import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from 'Components/text-input/TextInput';
import TimezoneSelect from 'Components/timezone/TimezoneSelect';
import UnmaskablePasswordInput from 'Components/password/UnmaskablePasswordInput';
import validate from './CreateAccountValidation';
import { sendPostRequest } from 'Http/request';
import moment from 'moment-timezone';
import Form from 'Components/form/Form';
import PageFrame from 'Components/page/PageFrame';
import { FormController } from 'Components/form/FormController';
import { getAtPath } from 'Common/util/FormUtils';

const StyledTextInput = styled(TextInput)`
    display: block;
`;

export default class CreateAccountForm extends React.Component {
    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
        pendingLinkingToken: PropTypes.string,
        pendingAssetName: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            initialValue: {
                organization_name: '',
                name: '',
                email: '',
                password: '',
                timezone: moment.tz.guess(),
            },
            initialTouched: {
                organization_name: false,
                name: false,
                email: false,
                password: false,
                timezone: false,
            },
        };
    }

    _handleSubmit = async (value, formController) => {
        const { onSuccess, pendingAssetName, pendingLinkingToken } = this.props;

        // Set everything as touched to expose all validation errors
        formController.setTouched({
            organization_name: true,
            name: true,
            email: true,
            password: true,
            timezone: true,
        });

        const validation = validate(value);

        if (validation) {
            formController.setSubmitError({ message: 'Some fields are invalid.' });
            return;
        }

        let requestBody = {
            email: value.email,
            name: value.name,
            password: value.password,
            organization_name: value.organization_name,
            timezone: value.timezone,
        };
        if (pendingAssetName && pendingLinkingToken) {
            requestBody.pending_linking_token = pendingLinkingToken;
            requestBody.pending_asset_name = pendingAssetName;
        }

        let response;

        try {
            response = await sendPostRequest('/api/v1/accounts/user', {
                body: requestBody,
            });
        }
        catch (err) {
            response = {
                ok: false,
                data: err || { message: 'Network error' },
            };
        }

        if (response.ok) {
            onSuccess(requestBody);
        }
        else {
            formController.setSubmitError(response.data);
        }
    };

    render() {
        const { initialValue, initialTouched } = this.state;

        return (
            <FormController
                initialValue={initialValue}
                initialTouched={initialTouched}
                validate={(value) => validate(value)}
                onSubmit={this._handleSubmit}
                onReset={this._handleReset}
                render={(formController) => {
                    const {
                        value,
                        touched,
                        validation,
                        isSubmitting,
                        submitError,

                        handleBlur,
                        handleChange,
                        handleSubmit,
                    } = formController;

                    const inputError = (inputName) => {
                        return getAtPath(touched, inputName) && getAtPath(validation, inputName);
                    };

                    const inputProps = (inputName, defaults = {}) => {
                        return {
                            name: inputName,
                            value: getAtPath(value, inputName),
                            error: !!inputError(inputName),
                            helperText: inputError(inputName) || defaults.helperText || '',
                            onChange: handleChange,
                            onBlur: handleBlur,
                            disabled: isSubmitting,
                        };
                    };

                    return (
                        <Form
                            onSubmit={handleSubmit}
                            allowSubmit={!isSubmitting}
                            submitButtonText="Create"
                            showCancel={false}
                            error={submitError}
                        >
                            <PageFrame title="Organization" collapsible={false}>
                                <StyledTextInput
                                    label="Organization Name"
                                    autoComplete="organization"
                                    placeholder="My Organization, Inc."
                                    required
                                    {...inputProps('organization_name')}
                                />
                            </PageFrame>

                            <PageFrame title="Your Account" collapsible={false}>
                                <StyledTextInput
                                    label="Your Name"
                                    autoComplete="name"
                                    placeholder="John Doe"
                                    required
                                    {...inputProps('name')}
                                />

                                <StyledTextInput
                                    label="Email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="email@example.com"
                                    required
                                    {...inputProps('email')}
                                />

                                <UnmaskablePasswordInput
                                    label='Password'
                                    autoComplete="new-password"
                                    required
                                    {...inputProps('password')}
                                />
                                <TimezoneSelect
                                    id="create-account-timezone"
                                    label="Time Zone"
                                    {...inputProps('timezone')}
                                />
                            </PageFrame>
                        </Form>
                    );
                }}
            />
        );
    }
}
