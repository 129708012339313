import React from 'react';
import PropTypes from 'prop-types';
import * as RequestStatus from 'Common/data/RequestStatus';
import UnmaskablePasswordInput from 'Components/password/UnmaskablePasswordInput';
import validate from './SetPasswordValidation';
import Form from 'Components/form/Form';

export default class SetPasswordForm extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        request: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            value: {
                password: '',
            },
            dirty: {
                password: false,
            },
        };
    }

    _handlePasswordChange = (event) => {
        const password = event.target.value;

        this.setState((state) => {
            return {
                value: {
                    ...state.value,
                    password: password,
                },
                dirty: {
                    ...state.dirty,
                    password: true,
                },
            };
        });
    };

    _handleSubmit = async (event) => {
        this.props.onSubmit({
            target: {
                name: this.props.name,
                value: this.state.value,
            },
        });
    };

    render() {
        const { request } = this.props;
        const { value, dirty } = this.state;

        const validation = validate(value);

        const isLoading = request.status === RequestStatus.PENDING;
        const isPasswordValid = !(validation?.password);
        const showPasswordError = dirty.password;
        const allowSubmit = isPasswordValid && !isLoading;

        return (
            <Form
                onSubmit={this._handleSubmit}
                allowSubmit={allowSubmit}
                submitButtonText="Set Password"
                showCancel={false}
                error={request.error}
            >
                <UnmaskablePasswordInput
                    disabled={isLoading}
                    onChange={this._handlePasswordChange}
                    error={showPasswordError && !isPasswordValid}
                    helperText={(showPasswordError && validation?.password) || ''}
                    value={value.password}
                />
            </Form>
        );
    }
}
